import { default as index0wZrjTLn2DMeta } from "/opt/buildhome/repo/pages/[username]/index.vue?macro=true";
import { default as _91featureid_93cnQCw5YsLPMeta } from "/opt/buildhome/repo/pages/channel/[channelid]/[nav]/[featureid].vue?macro=true";
import { default as indexLYU7AzCWL4Meta } from "/opt/buildhome/repo/pages/channel/[channelid]/[nav]/index.vue?macro=true";
import { default as indexBrann7swSKMeta } from "/opt/buildhome/repo/pages/channel/[channelid]/index.vue?macro=true";
import { default as indexGSt9fB8NnXMeta } from "/opt/buildhome/repo/pages/channel/index.vue?macro=true";
import { default as _91chatid_933x01XJlHQOMeta } from "/opt/buildhome/repo/pages/chat/[chatid].vue?macro=true";
import { default as chatcdcDIyUuf8Meta } from "/opt/buildhome/repo/pages/chat.vue?macro=true";
import { default as explorerYI361piByMeta } from "/opt/buildhome/repo/pages/explore.vue?macro=true";
import { default as index_copyhvKeYbnBJjMeta } from "/opt/buildhome/repo/pages/index_copy.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as mapsGFhSPoh41JMeta } from "/opt/buildhome/repo/pages/maps.vue?macro=true";
import { default as notificationqzXbaWb8jkMeta } from "/opt/buildhome/repo/pages/notification.vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as scheduleVfpgDdTWXkMeta } from "/opt/buildhome/repo/pages/schedule.vue?macro=true";
import { default as settingy5EQf90uLhMeta } from "/opt/buildhome/repo/pages/setting.vue?macro=true";
import { default as terms_45and_45conditionsPFDsB8eCTMMeta } from "/opt/buildhome/repo/pages/terms-and-conditions.vue?macro=true";
import { default as _91id_932nt7D8QWCYMeta } from "/opt/buildhome/repo/pages/timeline/[id].vue?macro=true";
import { default as indexYrCOLbJkHTMeta } from "/opt/buildhome/repo/pages/timeline/index.vue?macro=true";
export default [
  {
    name: index0wZrjTLn2DMeta?.name ?? "username",
    path: index0wZrjTLn2DMeta?.path ?? "/:username()",
    meta: index0wZrjTLn2DMeta || {},
    alias: index0wZrjTLn2DMeta?.alias || [],
    redirect: index0wZrjTLn2DMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/[username]/index.vue").then(m => m.default || m)
  },
  {
    name: _91featureid_93cnQCw5YsLPMeta?.name ?? "channel-channelid-nav-featureid",
    path: _91featureid_93cnQCw5YsLPMeta?.path ?? "/channel/:channelid()/:nav()/:featureid()",
    meta: _91featureid_93cnQCw5YsLPMeta || {},
    alias: _91featureid_93cnQCw5YsLPMeta?.alias || [],
    redirect: _91featureid_93cnQCw5YsLPMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/channel/[channelid]/[nav]/[featureid].vue").then(m => m.default || m)
  },
  {
    name: indexLYU7AzCWL4Meta?.name ?? "channel-channelid-nav",
    path: indexLYU7AzCWL4Meta?.path ?? "/channel/:channelid()/:nav()",
    meta: indexLYU7AzCWL4Meta || {},
    alias: indexLYU7AzCWL4Meta?.alias || [],
    redirect: indexLYU7AzCWL4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/channel/[channelid]/[nav]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBrann7swSKMeta?.name ?? "channel-channelid",
    path: indexBrann7swSKMeta?.path ?? "/channel/:channelid()",
    meta: indexBrann7swSKMeta || {},
    alias: indexBrann7swSKMeta?.alias || [],
    redirect: indexBrann7swSKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/channel/[channelid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGSt9fB8NnXMeta?.name ?? "channel",
    path: indexGSt9fB8NnXMeta?.path ?? "/channel",
    meta: indexGSt9fB8NnXMeta || {},
    alias: indexGSt9fB8NnXMeta?.alias || [],
    redirect: indexGSt9fB8NnXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/channel/index.vue").then(m => m.default || m)
  },
  {
    name: chatcdcDIyUuf8Meta?.name ?? "chat",
    path: chatcdcDIyUuf8Meta?.path ?? "/chat",
    meta: chatcdcDIyUuf8Meta || {},
    alias: chatcdcDIyUuf8Meta?.alias || [],
    redirect: chatcdcDIyUuf8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/chat.vue").then(m => m.default || m),
    children: [
  {
    name: _91chatid_933x01XJlHQOMeta?.name ?? "chat-chatid",
    path: _91chatid_933x01XJlHQOMeta?.path ?? ":chatid()",
    meta: _91chatid_933x01XJlHQOMeta || {},
    alias: _91chatid_933x01XJlHQOMeta?.alias || [],
    redirect: _91chatid_933x01XJlHQOMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/chat/[chatid].vue").then(m => m.default || m)
  }
]
  },
  {
    name: explorerYI361piByMeta?.name ?? "explore",
    path: explorerYI361piByMeta?.path ?? "/explore",
    meta: explorerYI361piByMeta || {},
    alias: explorerYI361piByMeta?.alias || [],
    redirect: explorerYI361piByMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: index_copyhvKeYbnBJjMeta?.name ?? "index_copy",
    path: index_copyhvKeYbnBJjMeta?.path ?? "/index_copy",
    meta: index_copyhvKeYbnBJjMeta || {},
    alias: index_copyhvKeYbnBJjMeta?.alias || [],
    redirect: index_copyhvKeYbnBJjMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index_copy.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mapsGFhSPoh41JMeta?.name ?? "maps",
    path: mapsGFhSPoh41JMeta?.path ?? "/maps",
    meta: mapsGFhSPoh41JMeta || {},
    alias: mapsGFhSPoh41JMeta?.alias || [],
    redirect: mapsGFhSPoh41JMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/maps.vue").then(m => m.default || m)
  },
  {
    name: notificationqzXbaWb8jkMeta?.name ?? "notification",
    path: notificationqzXbaWb8jkMeta?.path ?? "/notification",
    meta: notificationqzXbaWb8jkMeta || {},
    alias: notificationqzXbaWb8jkMeta?.alias || [],
    redirect: notificationqzXbaWb8jkMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/notification.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policypnUDKF1OY5Meta?.name ?? "privacy-policy",
    path: privacy_45policypnUDKF1OY5Meta?.path ?? "/privacy-policy",
    meta: privacy_45policypnUDKF1OY5Meta || {},
    alias: privacy_45policypnUDKF1OY5Meta?.alias || [],
    redirect: privacy_45policypnUDKF1OY5Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: scheduleVfpgDdTWXkMeta?.name ?? "schedule",
    path: scheduleVfpgDdTWXkMeta?.path ?? "/schedule",
    meta: scheduleVfpgDdTWXkMeta || {},
    alias: scheduleVfpgDdTWXkMeta?.alias || [],
    redirect: scheduleVfpgDdTWXkMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: settingy5EQf90uLhMeta?.name ?? "setting",
    path: settingy5EQf90uLhMeta?.path ?? "/setting",
    meta: settingy5EQf90uLhMeta || {},
    alias: settingy5EQf90uLhMeta?.alias || [],
    redirect: settingy5EQf90uLhMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/setting.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsPFDsB8eCTMMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsPFDsB8eCTMMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsPFDsB8eCTMMeta || {},
    alias: terms_45and_45conditionsPFDsB8eCTMMeta?.alias || [],
    redirect: terms_45and_45conditionsPFDsB8eCTMMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: _91id_932nt7D8QWCYMeta?.name ?? "timeline-id",
    path: _91id_932nt7D8QWCYMeta?.path ?? "/timeline/:id()",
    meta: _91id_932nt7D8QWCYMeta || {},
    alias: _91id_932nt7D8QWCYMeta?.alias || [],
    redirect: _91id_932nt7D8QWCYMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/timeline/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYrCOLbJkHTMeta?.name ?? "timeline",
    path: indexYrCOLbJkHTMeta?.path ?? "/timeline",
    meta: indexYrCOLbJkHTMeta || {},
    alias: indexYrCOLbJkHTMeta?.alias || [],
    redirect: indexYrCOLbJkHTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/timeline/index.vue").then(m => m.default || m)
  }
]