export const useApiGet: any = async (path: string, options: any = {}) => {
  const { $api }: any = useNuxtApp()
  return new Promise(resolve => {
    $api.get(path, options).then((response: any) => {
      resolve({
        status: true,
        data: response.data
      })
    }).catch((error: any) => {
      resolve({
        status: false,
        data: error
      })
    })
  })
}

export const useApiPost: any = async (path: string, params: any, options: any = {}) => {
  const { $api }: any = useNuxtApp()
  return new Promise(resolve => {
    $api.post(path, params, options || {}).then((response: any) => {
      resolve({
        status: true,
        data: response.data
      })
    }).catch((error: any) => {
      resolve({
        status: false,
        data: error
      })
    })
  })
}

export const useApiPut: any = async (path: string, params: any, options: any = {}) => {
  const { $api }: any = useNuxtApp()
  return new Promise(resolve => {
    $api.put(path, params, options || {}).then((response: any) => {
      resolve({
        status: true,
        data: response.data
      })
    }).catch((error: any) => {
      resolve({
        status: false,
        data: error
      })
    })
  })
}

export const useApiDelete: any = async (path: string, options: any = {}) => {
  const { $api }: any = useNuxtApp()
  return new Promise(resolve => {
    $api.delete(path, options || {}).then((response: any) => {
      resolve({
        status: true,
        data: response.data
      })
    }).catch((error: any) => {
      resolve({
        status: false,
        data: error
      })
    })
  })
}