import { VueFlow, Handle, useVueFlow, MarkerType } from '@vue-flow/core'
import { Background } from '@vue-flow/background'
import { ControlButton, Controls } from '@vue-flow/controls'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('VueFlow', VueFlow)
  nuxtApp.vueApp.component('VueFlowHandle', Handle)
  nuxtApp.vueApp.component('Background', Background)
  nuxtApp.vueApp.component('ControlButton', ControlButton)
  nuxtApp.vueApp.component('Controls', Controls)
  nuxtApp.provide('useVueFlow', useVueFlow)
  nuxtApp.provide('MarkerType', MarkerType)
})
