export default defineNuxtPlugin((nuxtApp: any) => {
  const { setScrollTop }: any = useScroll()
  nuxtApp.vueApp.directive('scroll', {
    created () {
      window.addEventListener('scroll', (event: any) => {
        const el = event.target
        // var left = (window.pageXOffset || el.scrollLeft) - (doc.clientLeft || 0);
        const top = (window.pageYOffset || el.scrollTop)  - (el.clientTop || 0)
        setScrollTop(top)
      })
    },
    unmounted () {
      window.removeEventListener('scroll', (event: any) => {
        console.log('scroll cleared : ', event)
        // Any code to be executed when the window is scrolled
      })
    }
  })
})
