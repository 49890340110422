export const useModal = () => {
  const nuxtApp: any = useNuxtApp()

  const dialogPrompt = useState<any>('dialogPrompt', () => {
    return {
      show: false,
      type: null,
      resolve: null,
      title: 'Confirm',
      description: null,
      form: null,
      act: {
        ok: 'Confirm',
        cancel: 'Cancel',
        reverse: false // reverse focus color
      }
    }
  })
  const setDialogPrompt = (t: any) => {
    dialogPrompt.value.show = t.show || false
    dialogPrompt.value.type = t.type || null
    dialogPrompt.value.title = t.title || dialogPrompt.value.title || null
    dialogPrompt.value.description = t.description || dialogPrompt.value.description || null
    dialogPrompt.value.form = t.form || null
    dialogPrompt.value.act.ok = t.act?.ok || dialogPrompt.value.act.ok || null
    dialogPrompt.value.act.cancel = t.act?.cancel || dialogPrompt.value.act.cancel || null
    dialogPrompt.value.act.reverse = t.act?.reverse || dialogPrompt.value.act.reverse || false
    dialogPrompt.value.resolve = t.resolve || null
  }
  
  return {
    dialogPrompt,
    setDialogPrompt
  }
}