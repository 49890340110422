import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
  Menu, MenuButton, MenuItems, MenuItem,
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/vue'


export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('TransitionRoot', TransitionRoot)
  nuxtApp.vueApp.component('TransitionChild', TransitionChild)
  nuxtApp.vueApp.component('Dialog', Dialog)
  nuxtApp.vueApp.component('DialogPanel', DialogPanel)
  nuxtApp.vueApp.component('DialogTitle', DialogTitle)
  nuxtApp.vueApp.component('Menu', Menu)
  nuxtApp.vueApp.component('MenuButton', MenuButton)
  nuxtApp.vueApp.component('MenuItems', MenuItems)
  nuxtApp.vueApp.component('MenuItem', MenuItem)
  nuxtApp.vueApp.component('Combobox', Combobox)
  nuxtApp.vueApp.component('ComboboxInput', ComboboxInput)
  nuxtApp.vueApp.component('ComboboxButton', ComboboxButton)
  nuxtApp.vueApp.component('ComboboxOptions', ComboboxOptions)
  nuxtApp.vueApp.component('ComboboxOption', ComboboxOption)
})