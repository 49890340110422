import axios from 'axios'

export default defineNuxtPlugin((nuxtApp: any) => {
  const config = useRuntimeConfig()
  // const dibsx = useCookie('dibsx')
  // const url = useRequestURL()
  // const route = useRoute()
  const baseUrl: string = config.public.baseURL

  const api = axios.create({
    baseURL: baseUrl,
    headers: {
      common: {}
    }
  })
  api.interceptors.request.use(async function (config: any) {
    let value = null
    if (process.client) {
      value = localStorage.getItem('warloc')
    }
    config.headers.Authorization = 'Bearer ' + value
    return config
  }, function (error: any) {
    return Promise.reject(error)
  })
return {
    provide: {
      api: api
    }
  }
})
