<script setup lang="ts">
const { auth, SubscribePush }: any = useAuth()
const { setWindowSize }: any = useWindowSize()

const urlBase64ToUint8Array = (base64String: any) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const subscribeUserToPush = async (registration: ServiceWorkerRegistration) => {
  const publicKey = 'BMXRN4BOlw6v8kmvxUBujg8FMwWYEajroGyL1wxeioZZrAUe2AkS6wspvNjS8R1zgFyFBSC42XlmxNfw-1Dw0PY';

  try {
    // Cek apakah sudah ada subscription aktif
    const existingSubscription = await registration.pushManager.getSubscription();

    if (existingSubscription) {
      // Jika sudah ada, perbarui ke server agar selalu up-to-date
      await SubscribePush(existingSubscription);
    } else {
      // Jika belum ada subscription, buat baru
      const newSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicKey),
      });
      // Kirim subscription baru ke server backend
      await SubscribePush(newSubscription);
    }
  } catch (error) {
    console.error('Error during push subscription', error);
  }
}

const InitSubscribe = () => {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      window.location.reload(); // Reload halaman ketika Service Worker baru aktif
    });

    navigator.serviceWorker.register('/sw.js').then((reg) => {
      reg.onupdatefound = () => {
        const newWorker: any = reg.installing;
        newWorker.onstatechange = () => {
          if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
            // Notifikasi ke user bahwa ada versi baru
            alert('A new version of the app is available. The page will reload to update.');
            newWorker.postMessage({ type: 'SKIP_WAITING' }); // Kirim pesan ke service worker untuk skip waiting
          }
        }
      }
    })

    if ('PushManager' in window) {
      navigator.serviceWorker.ready.then((registration) => {
        return registration.pushManager.getSubscription().then((subscription) => {
          subscribeUserToPush(registration);
        });
      });
    }
  }
}

watch(() => auth.value, (v: any) => {
  if (v) {
    InitSubscribe()
  }
}, { immediate: true, deep: true })

onMounted(() => {
  window.addEventListener('resize', () => {
    setWindowSize({
      width: screen.width,
      height: screen.height
    })
  }, true)
})

</script>

<template>
  <NuxtLayout />
</template>
