import Dexie, { type EntityTable } from 'dexie';

const db: any = new Dexie('wrlcencmsg')
export default defineNuxtPlugin(async (nuxtApp: any) => {
  const { messages }: any = useChat()
  const { auth }: any = useAuth()
  nuxtApp.provide('dbm', {
    connect: async (version: number) => {
      try {
        const dbs = {
          r: '++id, d, dt, dtread, a', // d: data encrypted, a: action (D/null), dt is last date, dtn is last message name, dtm is last message
          m: '++id, r, d, dt, a' // r: roomid, d: data encrypted, a: action (D/null) D is DELETED, dt is Date
        }
        return {
          status: true,
          data: db.version(version || 1).stores(dbs).upgrade((tx: any) => {
            // Will only be executed if a version below 2 was installed.
            return tx.table("m").toCollection().modify((d: any) => {
              d.a = ''
              return d
            })
          })
        }
      } catch {
        return {
          status: false,
          data: null
        }
      }
    },
    close: async () => {
      db.close()
    },
    get: async (key: string) => {
      return await db[key]
        .toArray()
    },
    getMessagesInRoom: async (chatroomid: string, limit: number = 100) => {
      try {
        return await db['m']
          .where('r')
          .equalsIgnoreCase(chatroomid)
          .sortBy('dt')

          // .reverse()
          // .sortBy('dt')
      } catch {
        return []
      }
    },
    clear: async () => {
      return await db.delete().then(() => {
        console.log('Clear DB Success, browser will reload!')
      }).catch((err: any) => {
        console.warn('Clear Offline DB Error!')
      }).finally(() => {
        location.reload()
      })
    },
    update: async (key: string, data: any) => {
      return new Promise(async (resolve) => {
        try {
          const x = await db[key].update(data.id, data)
          resolve({
            status: true,
            data: x
          })
        } catch {
          resolve({
            status: false,
            data: null
          })
        }
      })
    },
    updateRoomLastRead: async (data: any) => {
      if (data.chatroom_id && data.chatroom_member_last_read) {
        const oldRoom = await db.r.get(data.chatroom_id)
        if (oldRoom && oldRoom.d) {
          oldRoom.d.chatroom_member_last_read = data.chatroom_member_last_read
          oldRoom.dtread = new Date(data.chatroom_member_last_read)
          await db.r.update(oldRoom.id, oldRoom)
        }
      }
    },
    updateRoomWithLatestMessage: async (newDataMessage: any) => {
      const oldRoom = await db.r.get(newDataMessage.chatroom_id)
      if (oldRoom && oldRoom.d) {
        oldRoom.d.last_message = newDataMessage.message
        oldRoom.d.last_message_created = newDataMessage.created ? new Date(newDataMessage.created) : null
        oldRoom.d.last_message_user_id = newDataMessage.account_id
        oldRoom.d.last_message_user_image = newDataMessage.account_img
        oldRoom.d.last_message_user_name = newDataMessage.account_name
        await db.r.update(oldRoom.id, oldRoom)
        if (newDataMessage.created) {
          localStorage.setItem('s', newDataMessage.created)
        }
      }
    },
    delete: async (data: any, isRoom: boolean) => {
      // data => { id: ID ROOM/MESSAGE }
      // await db[isRoom ? 'r' : 'm'].update(data.id, { a: 'D' }).then((updated: any) => {
      //   const m = nuxtApp.$JsonRaw(messages.value)
      //   if (m && m.length && m[data.r]) {
      //     console.log(2)
      //   }
      // })
      if (isRoom) {
        // data => { id: ID ROOM }
      } else {
        // data => { id: ID MESSAGE, r: chatroom_id }
        const roomid = data.r
        if (data.id && roomid) {
          const oldMessage = await db.m.get(data.id)
          if (oldMessage && oldMessage.id && oldMessage.r) {
            oldMessage.a = 'D'
            oldMessage.d.message_a_type = 'DELETED'
            // await db.m.update(oldMessage.id, oldMessage)
            await db.m.delete(oldMessage.id)
          }
        }
      }
    },
    save: async (key: string, data: any) => {
      return new Promise(async (resolve) => {
        try {
          const x = await db[key].put(data)
          resolve({
            status: true,
            data: x
          })
        } catch {
          resolve({
            status: false,
            data: null
          })
        }
      })
    },
    saveBulk: async (key: string, data: any) => {
      return new Promise(async (resolve) => {
        try {
          resolve({
            status: true,
            data: await db[key].bulkPut(data)
          })
        } catch {
          resolve({
            status: false,
            data: null
          })
        }
      })
    },
    loadMessgesByRoom: async (r: string) => {
      return await db.m
        .where('r')
        .equalsIgnoreCase(r)
        .toArray()
    },
    setRead: async (data: any) => {
      const company = parseInt(data.roomid.split(':')[0] || '0') || 0
      if (parseInt(auth.value?.id) && company) {
        const key = 'k:' + (auth.value?.id + '' + company + '|' + data.roomid)
        const r = await db.chatroom.put({ key, user: parseInt(auth.value?.id), roomid: data.roomid, company, name: '', last_read_id: data.last_read_id })
        // await nuxtApp.$dbm.syncUnread()
        return r
      }
    },
    count: async (each: any) => {
      return await new Promise((resolve) => {
        const count = (messages.value[each.r].data.filter((s: any) => s.chatroom_id === each.r && s.id > each.last_read_id).length || 0)
        if (count) {
          resolve(count)
        } else {
          resolve(0)
        }
      })
    },
    lastid: async (roomid: any) => {
      // LAST LOAD MESSAGE ID
      return await db.messages
        .where('roomid')
        .equalsIgnoreCase(roomid)
        .last()
    },
    lastidread: async (roomid: any) => {
      // LAST READ MESSAGE ID
      const x = await db.chatroom
        .where('roomid')
        .equalsIgnoreCase(roomid)
        .toArray()
      if (x && x.length) {
        return x[0]
      }
      return {}
    },
    lastiddeleted: async (roomid: any) => {
      // LAST READ DELETED ID
      // return await db.messages
      //   .where('[roomid+message_attachment_type]')
      //   .equals([roomid, "DELETED"])
      //   .last()
      return await db.messages
        .where({ roomid, message_attachment_type: 'DELETED' })
        .last()
    }
  })
})
