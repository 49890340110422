import MyWorker from '@/assets/workers/workerVite?worker'

export const useChat = () => {
  const { $JsonRaw, $dbm, $Chat }: any = useNuxtApp()
  const route: any = useRoute()
  const router: any = useRouter()
  const { auth }: any = useAuth()
  const chatRooms = useState<any>('chatRooms', () => {
    return {
      loading: false,
      active: null,
      data: []
    }
  })

  const messages: any = useState<any>('messages', () => {
    return {}
  })
  
  const countRead = (chatroomID: string, lastRead: string) => {
    if (chatroomID && lastRead && chatRooms.value?.active?.id !== chatroomID) {
      const roomMessages = messages.value[chatroomID]
      if (roomMessages && roomMessages.length) {
        return roomMessages.filter((r: any) => {
          if (r.created && r.account_id !== auth.value?.id) {
            return (new Date(r.created)) > (new Date(lastRead))
          }
          return false
        }).length
      }
    }
    return 0
  }

  const countReads = computed(() => {
    const cActiveId = chatRooms.value?.active?.id
    const chatRoomsData = chatRooms.value?.data || []
    let countResult = 0
    for (let c = 0; c < chatRoomsData.length; c++) {
      const cc = chatRoomsData[c]
      if (cc.id !== cActiveId) {
        countResult += countRead(cc.id, cc.chatroom_member_last_read)
      }
    }
    return countResult
  })

  const SetMessage = async (v: any, forceAll?: boolean) => {
    return new Promise((resolve) => {
      if (v) {
        if (v.chatroom_id) {
          if (!messages.value) {
            messages.value = {}
          }
          if (!messages.value[v.chatroom_id]) {
            messages.value[v.chatroom_id] = []
          }
          if (forceAll) {
            messages.value[v.chatroom_id] = v
            resolve(true)
          } else {
            messages.value[v.chatroom_id].push($JsonRaw(v))
            resolve(true)
          }
        }
      }
    })
  }

  const sidebarScroll: any = useState<any>('sidebarScroll', () => 0)

  const chatMenu: any = useState<any>('chatMenu', () => false)
  const chatMenuToggle = () => {
    chatMenu.value = !chatMenu.value
  }

  const ChatRoomsGet = async (firstInit?: boolean) => {
    chatRooms.value.loading = true
    // REQUEST UPDATED ROOMS
    if (firstInit) {
      await ChatSync(firstInit, {
        id: `3:${auth.value?.id}`
      })
      const rMe = await $dbm.getMessagesInRoom(`3:${auth.value?.id}`, 500)
      for (let mMe = 0; mMe < rMe.length; mMe++) {
        const msgMe = rMe[mMe]
        if (msgMe && msgMe.d) {
          SetMessage(msgMe.d)
        }
      }
    }
    const response = await useApiGet('/realtime/rooms')
    if (response && response.status && response.data && response.data.rooms) {
      chatRooms.value.data = response.data.rooms || []  
      const rooms = $JsonRaw(chatRooms.value.data)
      if (rooms.length) {
        for (let a = 0; a < rooms.length; a++) {
          const x = rooms[a]
          // LOAD SAVED MESSAGES FROM LOCAL
          await ChatSync(firstInit, x)
          const r = await $dbm.getMessagesInRoom(x.id, 500)
          for (let m = 0; m < r.length; m++) {
            const msg = r[m]
            if (msg && msg.d) {
              SetMessage(msg.d)
            }
          }
        }
        // SAVE UPDATED ROOMS
        $dbm.saveBulk('r', (rooms || []).map((r: any) => {
          return {
            id: r.id,
            d: r,
            dt: r.last_message_created ? new Date(r.last_message_created) : null,
            dtread: r.chatroom_member_last_read ? new Date(r.chatroom_member_last_read) : null,
            a: '' // FOR DELETE PURPOSE
          }
        }))
      }
    }
    chatRooms.value.loading = false
    return await response
  }

  const SetChatRoomsActive = async (data: any, step: number) => {
    if (!data) {
      return
    }
    const r = $JsonRaw(data)
    const roomIndex = (chatRooms.value?.data || []).findIndex((r: any) => r.id === data.id)
    if (roomIndex > -1) {
      r.chatroom_member_last_read = new Date()
      const s = {
        id: r.id,
        d: r,
        dt: new Date(r.last_message_created),
        dtread: r.chatroom_member_last_read,
        a: ''
      }
      chatRooms.value.data[roomIndex].chatroom_member_last_read = (r.chatroom_member_last_read).toISOString()
      $Chat.roomRead(r.id)
      $dbm.update('r', s)
    }
    chatRooms.value.active = r
  }

  const DeleteMessage = async (id: string) => {
    const response = await useApiDelete(`/realtime/${id || 'null'}`)
    if (response && response.status) {
      const index = messages.value[route.params.chatid].findIndex((r: any) => r.id === id)
      if (index > -1) {
        const msg = messages.value[route.params.chatid][index]
        if (msg) {
          await $dbm.delete({ id: msg.id, r: route.params.chatid })
          messages.value[route.params.chatid].splice(index, 1)
        }
      }
    }
    return await response
  }

  const chatSyncLoading = useState<any>('chatSyncLoading', () => { return false })
  const ChatSync = async (firstInit?: boolean, wRoom?: any) => {
    chatSyncLoading.value = true
    const rooms = wRoom ? [wRoom] : await $dbm.get('r')
    for (let r = 0; r < rooms.length; r++) {
      const room: any = rooms[r]
      if (room && room.id) {
        const lastSync = firstInit || !room.dt ? (new Date('2000-01-01')).toISOString() : (new Date(room.dt)).toISOString()
        if (firstInit) {
          messages.value[room.id] = []
        }
        const response = await useApiPost('/realtime/sync', {
          roomid: room.id,
          lastSync: lastSync
        })
        if (response && response.data && response.status) {
          room.dt = response.data.last_created
          const msgs = response.data.messages || []
          if (!messages.value) {
            messages.value = {}
          }
          for (let m = 0; m < msgs.length; m++) {
            const mm = msgs[m]
            if (!messages.value[mm.chatroom_id]) {
              messages.value[mm.chatroom_id] = []
            }
            const msgExist = messages.value[mm.chatroom_id].find((r: any) => r.id === mm.id)
            if (msgExist) {
              messages.value[mm.chatroom_id].push(mm)
            }
          }
          
          // SAVE NEW UPDATED/SYNC MESSAGES TO LOCAL
          $dbm.saveBulk('m', (msgs || []).map((r: any) => {
            return {
              id: r.id,
              r: r.chatroom_id,
              d: Object.assign({}, r),
              dt: new Date(r.created),
              a: ''
            }
          }))
        }
      }
    }
    chatSyncLoading.value = false
  }

  const parseMessageContent = (content: string) => {
    // Deteksi format pesan
    const hasCodeBlock = content.includes('```');
    const hasMermaid = content.toLowerCase().includes('```mermaid');
    const hasMarkdown = /[*_`#\[\]]/g.test(content);
    const hasMathEquation = content.includes('$$') || content.includes('$');

    // Pisahkan konten menjadi segmen
    const segments = [];
    let currentPos = 0;

    // Handler untuk code blocks
    while (currentPos < content.length) {
      const codeStart = content.indexOf('```', currentPos);
      if (codeStart === -1) {
        // Tambahkan sisa konten sebagai text biasa
        if (currentPos < content.length) {
          segments.push({
            type: 'text',
            content: content.slice(currentPos)
          });
        }
        break;
      }

      // Tambahkan text sebelum code block
      if (codeStart > currentPos) {
        segments.push({
          type: 'text',
          content: content.slice(currentPos, codeStart)
        });
      }

      const codeEnd = content.indexOf('```', codeStart + 3);
      if (codeEnd === -1) break;

      const codeContent = content.slice(codeStart + 3, codeEnd);
      const languageMatch = codeContent.match(/^[^\n]+/);
      const language = languageMatch ? languageMatch[0].trim() : '';
      const code = languageMatch ? codeContent.slice(languageMatch[0].length).trim() : codeContent.trim();

      if (language.toLowerCase() === 'mermaid') {
        segments.push({
          type: 'mermaid',
          content: code
        });
      } else {
        segments.push({
          type: 'code',
          language,
          content: code
        });
      }

      currentPos = codeEnd + 3;
    }

    return segments;
  };

  return {
    messages,
    SetMessage,
    DeleteMessage,
    ChatRoomsGet,
    SetChatRoomsActive,
    chatSyncLoading,
    chatRooms,
    ChatSync,
    chatMenu,
    chatMenuToggle,
    countReads,
    countRead,
    parseMessageContent,
    sidebarScroll
  }
}
