import { createVuetify } from 'vuetify'
import { VVirtualScroll } from 'vuetify/components'

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    components: {
      VVirtualScroll, // Import hanya komponen VVirtualScroll
    },
  })

  nuxtApp.vueApp.use(vuetify)
})
