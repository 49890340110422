let socket: any = null
let observer: any = null
let curmid: number = 0
export default defineNuxtPlugin((nuxtApp: any) => {
  const config = useRuntimeConfig()
  const baseUrl: string = config.public.baseURL
  const { auth }: any = useAuth()
  const { chatRooms, messages, chatRoomActiveLoading, setChatRoomActive, chatRoomActive, chatRoomLastReadId, chatRoomLastReads, setMessages, addMessage, unreadMessage, unreadMessageSetOne, mentionMessage, mentionMessageSetOne }: any = useChat()

  const Chat: any = {
    ObserveMe: async (el: any) => {
      if (observer) {
        observer.observe(el)
      } else {
        const obs = nuxtApp.$Chat.Observer()
        if (obs) {
          obs.observe(el)
        }
      }
    },
    Observer: () => {
      if (process.server) {
        return
      }
      if (observer) {
        observer.Disconnect()
        observer = null
      }
      observer = new window.IntersectionObserver(nuxtApp.$Chat.ObserveHandler, {
        root: document.querySelector(".chat-inner-area"),
        rootMargin: "0px",
        threshold: 1.0
      })
      return observer
    },
    ObserveHandler: (entries: any) => {
      entries.forEach((entry: any) => {
        const e = entry?.target
        if (e) {
          const mid = e.getAttribute('mid')
          // if (curmid === 0) {
          //   curmid = mid
          // }
          if (parseInt(mid) && parseInt(mid) > curmid) {
            nuxtApp.$Chat.SetRoomRead(mid)
          }
        }
      })
      // entries[0].isIntersecting
    },
    Init: async () => {
      console.log('Init', socket)
      if (!socket) {
        const accesstoken = localStorage.getItem('warloc')
        if (accesstoken) {
          // @ts-ignore
          socket = await io((baseUrl || 'https://api.warloc.app'), {
            path: '/ws/',
            rejectUnauthorized: false,
            transports: ['websocket'],
            // withCredentials: true,
            // reconnectionDelayMax: 10000,
            query: {
              token: accesstoken
            }
          })
          await socket.on('connect_error', (err: any) => {
            console.log('connect_error due to: ', err.message)
            console.log(err.request)
          })
          await socket.on('connect_status', async (res: any) => {
            console.log('connected: ', res)
          })
          await socket.on('disconnect', function () {
            console.log('Disconnected')
            socket = null
          })
          await socket.on('room_joined', function (data: any) {
            console.log('Joined room :', data)
            // if (data.roomid) {
            //   nuxtApp.$Chat.LoadMessagesRequest(data.roomid)
            // }
          })
          await socket.on('room_leaved', function (data: any) {
            console.log('Leave room :', data)
            // if (data.roomid) {
            //   nuxtApp.$Chat.LoadMessagesRequest(data.roomid)
            // }
          })
          await socket.on('message_new', async (data: any) => {
            if (data) {
              if (data.chatroom_id === `3:${auth.value?.id}`) {
                if (!messages.value) {
                  messages.value = {}
                }
                if (!messages.value[data.chatroom_id]) {
                  messages.value[data.chatroom_id] = []
                }
                if (messages.value && messages.value[data.chatroom_id] && messages.value[data.chatroom_id].length) {
                  const msgIndex = messages.value[data.chatroom_id].findIndex((r: any) => r.uuid === data.uuid)
                  if (msgIndex > -1) {
                    messages.value[data.chatroom_id][msgIndex] = data
                  } else {
                    messages.value[data.chatroom_id].push(data)
                  }
                  nuxtApp.$dbm.save('m', {
                    id: data.id,
                    r: data.chatroom_id,
                    d: Object.assign({}, data),
                    dt: new Date(data.created),
                    a: ''
                  })
                }
              } else if (data.id && data.chatroom_id) {
                // Update room last_message
                const findIndexRoom = chatRooms.value.data.findIndex((r: any) => r.id === data.chatroom_id)
                if (findIndexRoom > -1) {
                  chatRooms.value.data[findIndexRoom].last_message = data.message
                  chatRooms.value.data[findIndexRoom].last_message_created = data.created
                  chatRooms.value.data[findIndexRoom].last_message_user_id = data.account_id
                  chatRooms.value.data[findIndexRoom].last_message_user_image = data.account_img
                  chatRooms.value.data[findIndexRoom].last_message_user_name = data.account_name
                  nuxtApp.$dbm.updateRoomWithLatestMessage(data)
                  if (!messages.value) {
                    messages.value = {}
                  }
                  if (!messages.value[data.chatroom_id]) {
                    messages.value[data.chatroom_id] = []
                  }
                  if (messages.value && messages.value[data.chatroom_id] && messages.value[data.chatroom_id].length) {
                    const msgIndex = messages.value[data.chatroom_id].findIndex((r: any) => r.uuid === data.uuid)
                    if (msgIndex > -1) {
                      messages.value[data.chatroom_id][msgIndex] = data
                    } else {
                      messages.value[data.chatroom_id].push(data)
                    }
                    nuxtApp.$dbm.save('m', {
                      id: data.id,
                      r: data.chatroom_id,
                      d: Object.assign({}, data),
                      dt: new Date(data.created),
                      a: ''
                    })
                  }
                }
              }
            }
          })
          await socket.on('message_deleted', async (data: any) => {
            if (data && data.roomid) {
              nuxtApp.$dbm.deleteMessage(data)
            }
          })
        }
      } else {
        return false
      }
    },
    Send (message: object) {
      if (socket) {
        socket.emit('message', message)
      }
    },
    RemoveMessage (roomid: string, id: number) {
      if (socket) {
        socket.emit('message_delete', {
          roomid,
          id
        })
      }
    },
    async roomRead (chatroomID: string) {
      if (chatroomID === `3:${auth.value?.id}`) {
        return
      }
      const lastRead = new Date()
      socket.emit('read-room', {
        chatroom_id: chatroomID,
        last_read: lastRead.toISOString()
      })
      const findIndexRoom = chatRooms.value.data.findIndex((r: any) => r.id === chatroomID)
      if (findIndexRoom > -1) {
        chatRooms.value.data[findIndexRoom].chatroom_member_last_read = lastRead.toISOString()
        await nuxtApp.$dbm.updateRoomLastRead({ chatroom_id: chatroomID, chatroom_member_last_read: lastRead.toISOString() })
      }
    },
    ScrollToBottom (isForce?: boolean) {
      const el = document.getElementById('chat-inner-area')
      if (el) {
        if (isForce) {
          el.setAttribute('style', 'opacity:0!important;')
        }
        setTimeout(() => {
          el.scrollTo(0, (el.scrollHeight || 0) + 200)
          el.setAttribute('style', 'scroll-behavior: smooth;')
        }, isForce ? 100 : 200)
      }
    },
    GetCompanyMemberRoomid (companyID: number, uid1: number, uid2: number) {
      if (parseInt(companyID + '') && parseInt(uid1 + '') && parseInt(uid2 + '')) {
        if (parseInt(uid1 + '') < parseInt(uid2 + '')) {
          return `${parseInt(companyID + '')}:${parseInt(uid1 + '')}:${parseInt(uid2 + '')}:COMPANY`
        } else {
          return `${parseInt(companyID + '')}:${parseInt(uid2 + '')}:${parseInt(uid1 + '')}:COMPANY`
        }
      }
      return null
    },
    roomJoin (roomid: string) {
      if (socket) {
        if (socket) {
          socket.emit('join-room', roomid)
        }
      }
      return false
    },
    roomLeave (roomid: string) {
      if (socket) {
        if (socket) {
          socket.emit('leave-room', roomid)
        }
      }
      return false
    },
    isConnected () {
      if (socket) {
        return socket.connected
      }
      return false
    },
    async disconnect () {
      if (socket) {
        socket.close()
        socket = null
        messages.value = {}
        chatRooms.value.loading = false
        chatRooms.value.active = null
        chatRooms.value.data = []
      }
    }
  }
  nuxtApp.provide('Chat', Chat)
})
