import vue3GoogleLogin from 'vue3-google-login'
import { decodeCredential } from 'vue3-google-login'

export default defineNuxtPlugin((app) => {
  app.vueApp.use(vue3GoogleLogin, {
    clientId: '99981875235-2kg6lhhb6i74eln4bggb1ks2bscparpj.apps.googleusercontent.com'
  })
  return {
    provide: {
      GoogleDecodeCredential: (d: any) => {
        // @ts-ignore
        return decodeCredential(d)
      }
    }
  }
})
