import { CheckIcon, ChevronUpDownIcon, ChevronRightIcon, ChatBubbleLeftIcon, SquaresPlusIcon, ChatBubbleLeftRightIcon, ArrowPathRoundedSquareIcon, ChevronDownIcon, Squares2X2Icon, MapIcon, MapPinIcon, ShieldExclamationIcon, LightBulbIcon, PencilIcon, PencilSquareIcon, PlusIcon, PlusCircleIcon, XMarkIcon, XCircleIcon, SpeakerWaveIcon, SpeakerXMarkIcon, CheckBadgeIcon, CheckCircleIcon, ClockIcon, ArrowTrendingUpIcon, ChevronUpIcon, SparklesIcon, UserGroupIcon } from '@heroicons/vue/20/solid'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('CheckIcon', CheckIcon)
  nuxtApp.vueApp.component('ChevronUpDownIcon', ChevronUpDownIcon)
  nuxtApp.vueApp.component('ChevronRightIcon', ChevronRightIcon)
  nuxtApp.vueApp.component('MapIcon', MapIcon)
  nuxtApp.vueApp.component('MapPinIcon', MapPinIcon)
  nuxtApp.vueApp.component('PencilIcon', PencilIcon)
  nuxtApp.vueApp.component('PencilSquareIcon', PencilSquareIcon)
  nuxtApp.vueApp.component('ShieldExclamationIcon', ShieldExclamationIcon)
  nuxtApp.vueApp.component('LightBulbIcon', LightBulbIcon)
  nuxtApp.vueApp.component('XMarkIcon', XMarkIcon)
  nuxtApp.vueApp.component('XCircleIcon', XCircleIcon)
  nuxtApp.vueApp.component('Squares2X2Icon', Squares2X2Icon)
  nuxtApp.vueApp.component('ChevronUpIcon', ChevronUpIcon)
  nuxtApp.vueApp.component('ArrowPathRoundedSquareIcon', ArrowPathRoundedSquareIcon)
  nuxtApp.vueApp.component('ChevronDownIcon', ChevronDownIcon)
  nuxtApp.vueApp.component('ClockIcon', ClockIcon)
  nuxtApp.vueApp.component('ArrowTrendingUpIcon', ArrowTrendingUpIcon)
  nuxtApp.vueApp.component('ChatBubbleLeftIcon', ChatBubbleLeftIcon)
  nuxtApp.vueApp.component('ChatBubbleLeftRightIcon', ChatBubbleLeftRightIcon)
  nuxtApp.vueApp.component('SquaresPlusIcon', SquaresPlusIcon)
  nuxtApp.vueApp.component('PlusIcon', PlusIcon)
  nuxtApp.vueApp.component('PlusCircleIcon', PlusCircleIcon)

  nuxtApp.vueApp.component('SpeakerWaveIcon', SpeakerWaveIcon)
  nuxtApp.vueApp.component('SpeakerXMarkIcon', SpeakerXMarkIcon)

  nuxtApp.vueApp.component('CheckCircleIcon', CheckCircleIcon)
  nuxtApp.vueApp.component('CheckBadgeIcon', CheckBadgeIcon)
  
  nuxtApp.vueApp.component('SparklesIcon', SparklesIcon)
  nuxtApp.vueApp.component('UserGroupIcon', UserGroupIcon)
})